import React from 'react';

import { SubHeadingContainer, SubHeadingText } from './styles/SubHeading.styles';

export const SubHeading = ({ className, id, children }) => {
  const tag = children.props.parentName;

  return (
    <SubHeadingContainer className={className} id={id}>
      <SubHeadingText as={tag}>{children}</SubHeadingText>
    </SubHeadingContainer>
  );
};
