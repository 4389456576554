import { CDN_BASE } from '../../constants/api';

export const logos = [
  {
    key: 'mightycause',
    name: 'Mightycause',
    logo: {
      src: `${CDN_BASE}/d6b6c962-9e7b-4d96-8bbf-b9d3f7d42a97/`,
      width: '200',
      sizes: '(max-width: 743px) 158px, 200px',
    },
  },
  {
    key: 'aryeo',
    name: 'Aryeo',
    logo: {
      src: `${CDN_BASE}/121cec38-0d04-4ceb-9ab8-04a657fe598e/`,
      width: '200',
      sizes: '(max-width: 743px) 158px, 200px',
    },
  },
  {
    key: 'cube',
    name: 'Cube',
    logo: {
      src: `${CDN_BASE}/be85a0d8-5035-41fc-b99c-276d98471b0b/`,
      width: '200',
      sizes: '(max-width: 743px) 158px, 200px',
    },
  },
  {
    key: 'snuggpro',
    name: 'SnuggPro',
    logo: {
      src: `${CDN_BASE}/63b476c9-93ad-4632-ba00-f388280c17c4/`,
      width: '200',
      sizes: '(max-width: 743px) 158px, 200px',
    },
  },
  {
    key: 'printhouse',
    name: 'Printhouse',
    logo: {
      src: `${CDN_BASE}/cb2ec461-ce78-449e-a5c4-f123d13baf63/`,
      width: '200',
      sizes: '(max-width: 743px) 158px, 200px',
    },
  },
  {
    key: 'polished',
    name: 'Polished',
    logo: {
      src: `${CDN_BASE}/40d5cb25-4935-49e1-ba2c-67e87aa9c7e2/`,
      width: '200',
      sizes: '(max-width: 743px) 158px, 200px',
    },
  },
  {
    key: 'zephyr',
    name: 'Zephyr',
    logo: {
      src: `${CDN_BASE}/e9e3ea1e-9102-466a-9de0-4e747452e7bc/`,
      width: '140',
      sizes: '(max-width: 743px) 112px, 140px',
    },
  },
  {
    key: 'supervisionassist',
    name: 'SupervisionAssist',
    logo: {
      src: `${CDN_BASE}/4fc8908a-89cc-4d44-90e5-e64ff90a2664/SupervisionAssistlogo.svg`,
      width: '240',
      sizes: '(max-width: 743px) 160px, 240px',
    },
  },
  {
    key: 'sundance',
    name: 'Sundance',
    logo: {
      src: `${CDN_BASE}/334152d6-49e3-4569-a6a7-911935e480e5/-/resize/196x/-/progressive/yes/-/format/auto/-/quality/normal/sundance.png`,
      width: '98',
      sizes: '(max-width: 743px) 74px, 93px',
    },
  },
  {
    key: 'webflow',
    name: 'Webflow',
    logo: {
      src: `${CDN_BASE}/3de71de8-d43b-48ca-9dd8-0e4f86ef1ca6/webflow.svg`,
      width: '140',
      sizes: '(max-width: 743px) 112px, 140px',
    },
  },
  {
    key: 'cisco',
    name: 'Cisco',
    logo: {
      src: `${CDN_BASE}/ba80dc53-24ae-4a37-b995-c1ae3b85bf9a/cisco.svg`,
      width: '93',
      sizes: '(max-width: 743px) 74px, 93px',
    },
  },
  {
    key: 'mozilla',
    name: 'Mozilla',
    logo: {
      src: `${CDN_BASE}/4a13234a-8e65-4636-88ce-a5950d6d7cb2/mozilla.svg`,
      width: '116',
      sizes: '(max-width: 743px) 92px, 116px',
    },
  },
  {
    key: 'universal',
    name: 'Universal',
    logo: {
      src: `${CDN_BASE}/1ae8d2f8-6160-4cd5-9fff-fe909353be76/universal.svg`,
      width: '128',
      sizes: '(max-width: 743px) 102px, 128px',
    },
  },
  {
    key: 'zillow',
    name: 'Zillow',
    logo: {
      src: `${CDN_BASE}/c054e7ab-5905-43b9-a39e-4afcb3f85b5e/zillow.svg`,
      width: '160',
      sizes: '(max-width: 743px) 128px, 160px',
    },
  },
  {
    key: 'loreal',
    name: 'L’Oreal',
    logo: {
      src: `${CDN_BASE}/fdedc96e-e192-4900-a25c-48c425ba1bbc/loreal.svg`,
      width: '140',
      sizes: '(max-width: 743px) 112px, 140px',
    },
  },
  {
    key: 'berkeley',
    name: 'Berkeley',
    logo: {
      src: `${CDN_BASE}/b786e9a1-b425-4786-9d78-df672283a4cf/berkeley.svg`,
      width: '147',
      sizes: '(max-width: 743px) 74px, 93px',
    },
  },
  {
    key: 'pandadoc',
    name: 'PandaDoc',
    logo: {
      src: `${CDN_BASE}/91eb3250-11bf-4d6b-b072-12ac15c59a83/pandadoc.svg`,
      width: '147',
      sizes: '(max-width: 743px) 74px, 93px',
    },
  },
  {
    key: 'splitmetrics',
    name: 'SplitMetrics',
    logo: {
      src: `${CDN_BASE}/efb7b760-7c6a-43dd-ad85-b7d350c9a383/splitmetrics.svg`,
      width: '147',
      sizes: '(max-width: 743px) 74px, 93px',
    },
  },
  {
    key: 'unidesk',
    name: 'Unidesk',
    logo: {
      src: `${CDN_BASE}/7875b1b5-bf7b-4aae-8416-11316a259626/unidesk.svg`,
      width: '147',
      sizes: '(max-width: 743px) 74px, 93px',
    },
  },
  {
    key: 'ycombinator',
    name: 'YCombinator',
    logo: {
      src: `${CDN_BASE}/38f73310-91c2-478b-8745-368812052f4f/ycombinator.svg`,
      width: '147',
      sizes: '(max-width: 743px) 74px, 93px',
    },
  },
  {
    key: 'snuggpro',
    name: 'SnuggPro',
    logo: {
      src: `${CDN_BASE}/c87dc881-ce1e-4de0-87a3-3508b8731608/snuggpro.svg`,
      width: '147',
      sizes: '(max-width: 743px) 74px, 93px',
    },
  },
  {
    key: 'stackshare',
    name: 'Stackshare',
    logo: {
      src: `${CDN_BASE}/3cd2e7cd-08fb-418f-b663-a4e65a7ae2c0/stackshare.svg`,
      width: '147',
      sizes: '(max-width: 743px) 74px, 93px',
    },
  },
  {
    key: 'chatra',
    name: 'Chatra',
    logo: {
      src: `${CDN_BASE}/5bf019ce-03a4-49e4-9582-8226aad79811/-/resize/360x/-/progressive/yes/-/format/auto/-/quality/normal/chatra.png`,
      width: '180',
    },
  },
  {
    key: 'celearning',
    name: 'CE Learning Systems',
    logo: {
      src: `${CDN_BASE}/bd4aeb9d-39f0-4f98-8c9f-4c55f769da4e/celearning.svg`,
      width: '224',
    },
  },
  {
    key: 'cindicator',
    name: 'Cindicator',
    logo: {
      src: `${CDN_BASE}/61e775a3-2d78-4baa-b3be-fdded6727a08/cindicator.svg`,
      width: '259',
    },
  },
  {
    key: 'metigy',
    name: 'Metigy',
    logo: {
      src: `${CDN_BASE}/3e042ab2-22d1-477d-a9fa-6082f74d6932/metigy.svg`,
      width: '190',
    },
  },
  {
    key: 'moodzer',
    name: 'Moodzer',
    logo: {
      src: `${CDN_BASE}/5a04683a-4d7d-4997-a2cb-ae9bf9727ae5/moodzer.svg`,
      width: '205',
    },
  },
  {
    key: 'timepad',
    name: 'Timepad',
    logo: {
      src: `${CDN_BASE}/a0cfa23b-ebfa-45d6-8a53-ae4e793fec2d/timepad.svg`,
      width: '200',
    },
  },
  {
    key: 'upscribe',
    name: 'Upscribe',
    logo: {
      src: `${CDN_BASE}/6ec53606-1fce-4e8f-b269-4cf69ea00f6f/upscribe.svg`,
      width: '211',
    },
  },
  {
    key: 'sfstate',
    name: 'San Francisco State University',
    logo: {
      src: `${CDN_BASE}/a23ca3b8-5283-4081-9f7c-7bfa683b632a/sfstatebw.svg`,
      width: '275',
    },
  },
  {
    key: 'prezly',
    name: 'Prezly',
    logo: {
      src: `${CDN_BASE}/56cb80ce-29e9-4eb5-8425-d1aa66ac5b1c/prezlybw.svg`,
      width: '180',
    },
  },
  {
    key: 'indeed',
    name: 'Indeed',
    logo: {
      src: `${CDN_BASE}/5d84511e-a29b-4742-a1cc-f78ec28cd739/indeedbw.svg`,
      width: '176',
    },
  },
  {
    key: 'ireland',
    name: 'Government of Ireland',
    logo: {
      src: `${CDN_BASE}/662ae737-c22a-4c67-b5cc-fb9ce3603f21/irelandbw.png`,
      width: '200',
    },
  },
  {
    key: 'ireland',
    name: 'Government of Ireland',
    logo: {
      src: `${CDN_BASE}/662ae737-c22a-4c67-b5cc-fb9ce3603f21/irelandbw.png`,
      width: '200',
    },
  },
  {
    key: 'philippines',
    name: 'Philippines',
    logo: {
      src: `${CDN_BASE}/cb256c32-e4cb-475e-bfc7-d45e6fa9f6a9/philippinesbw.png`,
      width: '199',
    },
  },
  {
    key: 'totango',
    name: 'Totango',
    logo: {
      src: `${CDN_BASE}/a06e183d-45bb-47d5-bad6-6d432b0229e6/totangobw.svg`,
      width: '200',
    },
  },
  {
    key: 'shogun',
    name: 'Shogun',
    logo: {
      src: `${CDN_BASE}/c976e18e-3303-4335-b0e3-d16caf5c2989/shogun.svg`,
      width: '157',
    },
  },
  {
    key: 'framefox',
    name: 'Framefox',
    logo: {
      src: `${CDN_BASE}/1d07c30b-e3c2-4b54-9129-0867e95b8eef/framefox.svg`,
      width: '240',
      offsetY: -4,
    },
  },
  {
    key: 'instamag',
    name: 'Instamag',
    logo: {
      src: `${CDN_BASE}/b1944c16-bd9d-4e7c-93c2-bd309a53ce0d/instamagbw.svg`,
      width: '180',
      offsetY: 10,
    },
  },
  {
    key: 'teopiki',
    name: 'Teopiki',
    logo: {
      src: `${CDN_BASE}/71aee76e-6d41-4906-9874-b4982ab5f244/teopiki.svg`,
      width: '170',
    },
  },
];
