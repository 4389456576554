import styled, { css } from 'styled-components';

import { StorySection } from '../../StorySection/StorySection';
import { ParagraphSection } from '../../Paragraph/Paragraph';

export const SubHeadingContainer = styled(StorySection)(
  ({ theme: { tablet } }) => css`
    align-items: flex-start;
    scroll-margin-top: var(--navigation-height, 46px);

    /* add more padding if placed after paragraph */
    ${ParagraphSection} + & {
      margin-top: 52px;
    }

    h2,
    h3,
    h4 {
      text-align: left;
    }

    h2 {
      font-size: 24px;

      ${tablet()} {
        font-size: 36px;
      }
    }

    h3 {
      font-size: 20px;

      ${tablet()} {
        font-size: 28px;
      }
    }

    h4 {
      font-size: 16px;

      ${tablet()} {
        font-size: 22px;
      }
    }
  `
);

export const SubHeadingText = styled.h2`
  font-family: var(--font-headings);
  font-weight: 600;
  line-height: 1.3;
  text-align: center;
  color: var(--color-dark);
  padding: 12px 0 20px;

  a {
    text-decoration: none;
    color: inherit;
  }
`;
