import React from 'react';
import { imgFallback, srcSet } from '~/src/utils/responsive-images';

import { ImageWrapper } from '~/src/mdx/components/StoryCards/styles/styled-components';

export const CardImage = ({ uuid, modifiers, alt, fullWidth }) => {
  const makeModifiers = (previewBox) =>
    `${modifiers ? `${modifiers}-` : ''}/scale_crop/${previewBox.join('x')}/center`;

  const makeSrcSet = (previewBox) => srcSet(uuid, makeModifiers(previewBox));
  const makeFallback = (previewBox) => imgFallback(uuid, makeModifiers(previewBox));

  const previewBox = fullWidth ? [800, 480] : [400, 240];

  return (
    <ImageWrapper>
      <picture>
        <source srcSet={makeSrcSet(previewBox)} media="(max-width: 479px)" />
        <source srcSet={makeSrcSet(previewBox)} media="(max-width: 743px)" />
        <source srcSet={makeSrcSet(previewBox)} />
        <img src={makeFallback(previewBox)} alt={alt} />
      </picture>
    </ImageWrapper>
  );
};
