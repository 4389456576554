/* Vendors */
import React from 'react';
import { STORY_TYPE_ENUM } from './types';

import { StoryCardsContainer } from './styles/styled-components';
import { CustomerCard } from './components/CustomerCard';
import { LearningCard } from './components/LearningCard';

export const StoryCards = ({ className, stories }) => (
  <StoryCardsContainer className={className}>
    {stories.map((story) => {
      switch (story.type) {
        case STORY_TYPE_ENUM.CUSTOMER:
          return <CustomerCard key={story.id} story={story} />;
        case STORY_TYPE_ENUM.LEARNING:
          return <LearningCard key={story.id} story={story} />;
        default:
          return null;
      }
    })}
  </StoryCardsContainer>
);
