/* Vendors */
import React from 'react';
import styled from 'styled-components';

/* Navigations */

/* Utils */

/* Types */

/* Styles */

const StyledStrong = styled.strong`
  font-weight: 600;
`;

/* Component */
export const Strong = ({ className, children }) => (
  <StyledStrong className={className}>{children}</StyledStrong>
);

/* default props */
Strong.defaultProps = {};
