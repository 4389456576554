/* Vendors */
import React from 'react';
import styled from 'styled-components';

/* Components */
import { Link } from '../../../components/Link/Link';
import { StorySection } from '../StorySection/StorySection';
import { CustomerLogo } from '../../../components/CustomerLogo/CustomerLogo';
import { Global12pxArrowThinRight, Global12pxArrowThinLeft } from '../icons';

/* Utils */
import { withMedia } from '../../../utils/withMedia';

/* Types */

/* Styles */

const Wrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: [prev] 1fr [company] 1fr [next] 1fr;

  @media (${withMedia('--small')}) {
    grid-template: 'company company' auto 'prev next' auto;
    grid-gap: 24px;
  }
`;

const StyledLinkIcon = styled.svg`
  fill: var(--color-grey);

  &:first-child {
    margin-right: 12px;
  }

  &:last-child {
    margin-left: 12px;
  }
`;

const StyledLink = styled(Link)`
  font-family: var(--font-headings);
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.7px;
  display: flex;
  align-items: center;

  &,
  &:active,
  &:visited,
  &:visited:active {
    color: var(--color-grey);
  }

  &:hover,
  &:active,
  &:focus,
  &:visited:hover,
  &:visited:focus {
    color: #9b9b9b;

    ${StyledLinkIcon} {
      fill: #9b9b9b;
    }
  }
`;

const GridCell = styled.div`
  display: flex;
  grid-row: ${({ row }) => row};

  justify-content: ${({ align }) =>
    ({
      left: 'flex-start',
      right: 'flex-end',
      center: 'center',
    })[align]};
`;

const CompanyHeader = styled.div`
  height: 52px;
  display: flex;
  justify-content: center;
  grid-row: company;

  @media (${withMedia('--small')}) {
    grid-column: span 2;
  }

  img {
    height: 100%;
  }

  a {
    font-family: var(--font-headings);
    text-align: center;
    font-size: 24px;
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: 4px;
    color: var(--color-dark);
    text-transform: uppercase;
    text-decoration: none;

    &,
    &:active,
    &:visited,
    &:visited:active {
      color: var(--color-dark);
    }

    &:hover,
    &:active,
    &:focus,
    &:visited:hover,
    &:visited:focus {
      color: var(--color-grey);
    }
  }
`;

/* Component */
export const Navigation = ({ className, logo, nextStoryUrl, indexLink = {} }) => (
  <StorySection className={className} verticalBase={40}>
    <Wrapper>
      <GridCell align="left" row="prev">
        <StyledLink visual="strong" to={indexLink.url}>
          <StyledLinkIcon as={Global12pxArrowThinLeft} />
          {indexLink.text}
        </StyledLink>
      </GridCell>
      <CompanyHeader>
        {Boolean(logo) && (
          <a href={logo.url} target="_blank" rel="noopener noreferrer">
            <CustomerLogo name={logo.name} />
          </a>
        )}
      </CompanyHeader>
      {Boolean(nextStoryUrl) && (
        <GridCell align="right" row="next">
          <StyledLink visual="strong" to={nextStoryUrl} align="right">
            Next story
            <StyledLinkIcon as={Global12pxArrowThinRight} />
          </StyledLink>
        </GridCell>
      )}
    </Wrapper>
  </StorySection>
);
