import React from 'react';

import {
  CardWrapper,
  Card,
  Text,
  CardFakeLink,
  StyledLinkIcon,
} from '../../styles/styled-components';
import { Global12pxArrowThinRight } from '../../../icons';

export const LearningCard = ({ story }) => {
  const { id, path, frontmatter } = story;

  if (!frontmatter) {
    // eslint-disable-next-line no-console
    console.error('Missing story exports', story);
    return null;
  }

  return (
    <CardWrapper visual="strong" to={path}>
      <Card key={id}>
        <Text>{frontmatter.title}</Text>
        <CardFakeLink>
          Learn
          <StyledLinkIcon as={Global12pxArrowThinRight} />
        </CardFakeLink>
      </Card>
    </CardWrapper>
  );
};
