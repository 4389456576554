/* Vendors */
import React from 'react';
import styled from 'styled-components';

/* Components */
import { StorySection } from '../StorySection/StorySection';
import { CallToAction } from '../../../components/CallToAction/CallToAction';
import { PaddingBox } from '../PaddingBox/PaddingBox';
import { StoryCards } from '../StoryCards/StoryCards';

/* Utils */
import { withMedia } from '../../../utils/withMedia';

/* Types */

/* Styles */

const StyledHeading = styled.h2`
  font-family: var(--font-headings);
  font-size: 56px;
  font-weight: 500;
  line-height: 1.29;
  text-align: center;
  margin-bottom: 24px;

  @media (${withMedia('--small')}) {
    font-size: 32px;
  }
`;

const StyledSubHeading = styled.h2`
  font-size: 26px;
  text-align: center;
  font-weight: normal;
  margin: 24px 0px;
`;

/* Content */

/* StoryFooter */
export const StoryFooter = ({ className, allStories }) => (
  <PaddingBox topBase={140}>
    <StorySection className={className} visual="ecru-white" maxWidth={980} topBase={80} bottom={80}>
      <StyledHeading>
        Handling files is hard. <br />
        We make it easy.
      </StyledHeading>
      <PaddingBox vertical={20}>
        <CallToAction text="Try it free" to="/pricing/" />
      </PaddingBox>
      <StyledSubHeading>Read similar stories</StyledSubHeading>
      <PaddingBox vertical={16}>
        <StoryCards stories={allStories} />
      </PaddingBox>
    </StorySection>
  </PaddingBox>
);

/* default props */
StoryFooter.defaultProps = {};
