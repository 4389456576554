/* Vendors */
import React from 'react';

import { StyledSection, StyledUList } from '~/src/mdx/components/List/styles/List.styles';

export const UList = ({ className, children }) => (
  <StyledSection className={className}>
    <StyledUList>{children}</StyledUList>
  </StyledSection>
);
