import styled, { css } from 'styled-components';
import { withMedia } from '~/src/utils/withMedia';
import { CustomerLogo } from '~/src/components/CustomerLogo/CustomerLogo';
import { Quote } from '~/src/mdx/components/Quote/Quote';
import { PaddingBox } from '~/src/mdx/components/PaddingBox/PaddingBox';
import { Link } from '~/src/components/Link';

export const StoryCardsContainer = styled.div`
  --columns: 2;
  display: grid;
  grid-template-columns: repeat(var(--columns), 1fr);
  grid-gap: 60px;
  width: 100%;

  @media (${withMedia('--small')}) {
    --columns: 1;
  }
`;

export const ImageWrapper = styled.div`
  width: 100%;
  margin-bottom: 16px;
  text-align: center;
`;

export const Text = styled.div`
  font-family: var(--font-headings);
  font-size: 24px;
  line-height: 1.5;
  text-align: center;
  flex: 1;
  margin-bottom: 24px;
`;

export const Logo = styled(CustomerLogo)`
  margin: 24px 0;
  transform: scale(0.8);
  max-width: 100%;
`;

export const StyledQuote = styled(Quote)`
  margin-bottom: 24px;
  text-align: center;
  font-weight: 500;
`;

export const StyledLinkIcon = styled.svg`
  margin-left: 8px;
`;

export const Card = styled(PaddingBox)`
  background-color: var(--color-light);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translateY(0px);
  transition: transform 0.15s ease-in-out;
  cursor: pointer;
  height: 100%;

  &:hover {
    transform: translateY(-10px);
  }

  ${({ fullWidth }) =>
    fullWidth
      ? css`
          grid-column: span var(--columns);
          --padding-horizontal-base: 120px;
        `
      : css`
          --padding-horizontal: 30px;
        `};

  ${({ withImage }) =>
    withImage
      ? css`
          --padding-vertical: 30px;
        `
      : css`
          --padding-vertical-base: 64px;
        `}
`;

export const CardWrapper = styled(Link)`
  &,
  &:active,
  &:visited,
  &:visited:active {
    outline-offset: 16px;
    color: inherit;
  }

  &:hover,
  &:focus,
  &:visited:hover,
  &:visited:focus {
    color: inherit;
  }
`;

export const CardFakeLink = styled.span`
  font-family: var(--font-headings);
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: 0.8px;
  text-align: center;
  color: #0e54ff;

  &:hover {
    color: var(--color-dark);
  }
`;
