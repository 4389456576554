/* Vendors */
import React from 'react';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';

/* Components */
import { SiteLayout } from '~/src/components/Layout/SiteLayout';
import { Navigation } from '~/src/mdx/components/Navigation/Navigation';
import { Paragraph } from '~/src/mdx/components/Paragraph/Paragraph';
import { Heading } from '~/src/mdx/components/Heading/Heading';
import { SubHeading } from '~/src/mdx/components/SubHeading';
import { UList } from '~/src/mdx/components/List';
import { StoryFooter } from '~/src/mdx/components/StoryFooter/StoryFooter';
import { Strong } from '~/src/mdx/components/Strong/Strong';

/* Utils */
import { CDN_BASE } from '~/src/constants/api';
import { mapStories, mapStory } from '../helpers/story-selectors';

const getNextStoryUrl = (currentStory, allStories) => {
  const storyIdx = allStories.findIndex((story) => story.id === currentStory.id);
  const nextStoryIdx = storyIdx + 1 < allStories.length ? storyIdx + 1 : 0;
  const nextStoryPath = allStories[nextStoryIdx].path;

  return nextStoryPath;
};

/* Page */
const StoryTemplate = ({ location, data: { mdx, allMdx } }) => {
  const { pathname } = location;

  const currentStory = mapStory(mdx);
  const allStories = mapStories(allMdx);
  const nextStoryUrl = getNextStoryUrl(currentStory, allStories);

  const meta = {
    ...currentStory.frontmatter,
    title: `${currentStory.companyInfo.name}: ${currentStory.frontmatter.title}`,
    image:
      currentStory.frontmatter.image &&
      `${CDN_BASE}/${currentStory.frontmatter.image}/-/progressive/yes/-/format/auto/-/quality/normal/`,
  };

  return (
    <SiteLayout meta={meta} pathName={pathname}>
      <Navigation
        logo={{
          url: currentStory.companyInfo.url,
          name: currentStory.companyInfo.name,
        }}
        nextStoryUrl={nextStoryUrl}
        indexLink={{
          url: `/${mdx.fields.sourceName}/`,
          text: 'Customers',
        }}
      />
      <MDXProvider
        components={{
          p: Paragraph,
          strong: Strong,
          h1: Heading,
          h2: SubHeading,
          h3: SubHeading,
          ul: UList,
        }}
      >
        <MDXRenderer>{currentStory.code}</MDXRenderer>
      </MDXProvider>
      <StoryFooter allStories={allStories.filter((story) => story.id !== currentStory.id)} />
    </SiteLayout>
  );
};

export default StoryTemplate;

export const pageQuery = graphql`
  query ($id: String!, $sourceName: String!) {
    mdx(id: { eq: $id }) {
      ...StoryBase
      ...StoryExports
      ...StoryCode
    }
    allMdx(
      filter: { exports: { disabled: { ne: true } }, fields: { sourceName: { eq: $sourceName } } }
    ) {
      edges {
        node {
          ...StoryBase
          ...StoryExports
        }
      }
    }
  }
`;
