import React from 'react';

import {
  CardWrapper,
  Card,
  Logo,
  StyledQuote,
  Text,
  CardFakeLink,
  StyledLinkIcon,
} from '../../styles/styled-components';
import { Global12pxArrowThinRight } from '../../../icons';
import { CardImage } from '../CardImage';

export const CustomerCard = ({ story }) => {
  const { id, path, cardOptions, companyInfo, frontmatter } = story;

  if (!frontmatter || !cardOptions || !companyInfo) {
    // eslint-disable-next-line no-console
    console.error('Missing story exports', story);

    return null;
  }

  return (
    <CardWrapper visual="strong" to={path}>
      <Card withImage={!!cardOptions.image} fullWidth={cardOptions.fullWidth} key={id}>
        {cardOptions.image && (
          <CardImage
            uuid={cardOptions.image.uuid}
            modifiers={cardOptions.image.modifiers}
            alt={companyInfo.name}
          />
        )}
        {cardOptions.logo && <Logo name={companyInfo.logo} />}
        {cardOptions.quote ? (
          <StyledQuote big={cardOptions.fullWidth}>
            {cardOptions.text || frontmatter.title}
          </StyledQuote>
        ) : (
          <Text>{cardOptions.text || frontmatter.title}</Text>
        )}
        <CardFakeLink>
          Read story
          <StyledLinkIcon as={Global12pxArrowThinRight} />
        </CardFakeLink>
      </Card>
    </CardWrapper>
  );
};
