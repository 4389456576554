import styled, { css } from 'styled-components';

import { StorySection } from '~/src/mdx/components/StorySection/StorySection';
import { ParagraphSection } from '~/src/mdx/components/Paragraph/Paragraph';
import { withMedia } from '~/src/utils/withMedia';

export const StyledSection = styled(StorySection)`
  align-items: flex-start;
  margin-bottom: 32px;

  /* add more padding if placed after paragraph */
  ${ParagraphSection} + & {
    margin-top: 32px;
  }
`;

const listStyles = css`
  font-family: var(--font-headings);
  font-size: 19px;
  line-height: 2;
  text-align: left;

  color: var(--color-dark);
  margin: 0 0 0 36px;
  padding: 0;

  & > a {
    text-decoration: none;
    color: var(--color-dark);
  }

  @media (${withMedia('--small')}) {
    font-size: 17px;
  }
`;

export const StyledUList = styled.ul`
  ${listStyles};
  list-style-type: disc;
`;

export const StyledOList = styled.ol`
  ${listStyles};
  margin: 0;
`;
