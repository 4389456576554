import { STORY_TYPE_ENUM } from '~/src/mdx/components/StoryCards/types';

export const mapStory = (mdxStory) => ({
  id: mdxStory.id,
  frontmatter: mdxStory.frontmatter,
  name: mdxStory.fields && mdxStory.fields.name,
  path: mdxStory.fields && mdxStory.fields.path,
  cardOptions: mdxStory.exports && mdxStory.exports.cardOptions,
  companyInfo: mdxStory.exports && mdxStory.exports.companyInfo,
  code: mdxStory.body,
  type: STORY_TYPE_ENUM.CUSTOMER,
});

export const mapStories = (allMdx) => allMdx.edges.map(({ node }) => mapStory(node));
